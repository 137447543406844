import Vue from "vue";
import VueRouter from "vue-router";
// import HomePage from "@/views/HomePage.vue";
// import CompanyIntroduction from "@/views/CompanyIntroduction.vue";
// import ContactUs from "@/views/ContactUs.vue";
// import ProductService from "@/views/ProductService.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/HomePage",
    name: "HomePage",
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: "/CompanyIntroduction",
    name: "CompanyIntroduction",
    component: () => import('@/views/CompanyIntroduction.vue'),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: () => import('@/views/ContactUs.vue'),
  },
  {
    path: "/ProductService",
    name: "ProductService",
    component: () => import('@/views/ProductService.vue'),
    children: [
      {
        path: "/RemoteSensingData",
        name: "RemoteSensingData",
        component: () =>
          import("@/components/ServiceTabs/RemoteSensingData.vue"),
      },
    ],
  },
  {
    path: '/',
    redirect: '/HomePage'
  }
];

// 跳转到页面最顶部

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  routes,
  scrollBehavior,
});

export default router;
