import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 引入百度地图
import BaiduMap from "vue-baidu-map";
import { VueJsonp } from "vue-jsonp";

Vue.use(VueJsonp);
// 使用百度地图，并配置自己的 ak
Vue.use(BaiduMap, { ak: "l2SXHo1g1zdOAnBFm2QLhzoN2zXFSgt9" });
